(function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = 'https://connect.facebook.net/fr_FR/sdk.js#xfbml=1&version=v2.11';
    fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));

(function ($) {

    var $facebook = $("#share-facebook"),
        $twitter = $("#share-twitter"),
        $linkedin = $("#share-linkedin"),
        $mail = $("#share-mail"),
        allShare = [
            {
                id: $facebook,
                topShow: "0",
                leftShow: "-4.333rem",
                topHide: "0.5556rem",
                leftHide: "0"
            },
            {
                id: $twitter,
                topShow: "3.667rem",
                leftShow: "-3.667rem",
                topHide: "0.8333rem",
                leftHide: "0"
            },
            {
                id: $linkedin,
                topShow: "5.056rem",
                leftShow: "0",
                topHide: "1.111rem",
                leftHide: "10px"
            },
            {
                id: $mail,
                topShow: "3.667rem",
                leftShow: "3.667rem",
                topHide: "0.8333rem",
                leftHide: "1rem"
            }];

    var triggerBookmark = $("#bookmarkme");

    triggerBookmark.click(function () {
        addBookmark();
    });

    $("#printer").click(function () {
        window.print();
    });

    $("#share-btn").toggle(function () {
        var temp = allShare.slice();
        $(this).attr("aria-expanded", "true");
        animeShare(true, "2.778rem", temp);
    }, function () {
        var temp = allShare.slice();
        $(this).attr("aria-expanded", "false");
        animeShare(false, "1.667rem", temp);
    });

    function addBookmark() {
        if (window.sidebar && window.sidebar.addPanel) { // Firefox <23
            window.sidebar.addPanel(document.title, window.location.href, '');
        } else if (window.external && ('AddFavorite' in window.external)) { // Internet Explorer
            window.external.AddFavorite(location.href, document.title);
        } else if (window.opera && window.print || window.sidebar && !(window.sidebar instanceof Node)) { // Opera <15 and Firefox >23
            /**
             * For Firefox <23 and Opera <15, no need for JS to add to bookmarks
             * The only thing needed is a `title` and a `rel="sidebar"`
             */
            triggerBookmark.attr('rel', 'sidebar').attr('title', document.title);
            return true;
        } else { // For the other browsers (mainly WebKit) we use a simple alert to inform users that they can add to bookmarks with ctrl+D/cmd+D
            alert('Vous pouvez ajouter cette page en favori en cliquant sur l\'étoile dans votre barre d\'adresse ou en appuyant sur les touches ' + (navigator.userAgent.toLowerCase().indexOf('mac') != -1 ? 'Command/Cmd' : 'CTRL') + ' + D de votre clavier.');
        }
    }

    function animeShare(toShow, size, allShare) {
        var time = 150;

        if (toShow) {
            allShare[0].id.css("display", "flex");
            allShare[0].id.animate({
                top: allShare[0].topShow,
                left: allShare[0].leftShow,
                width: size,
                height: size
            }, time, function () {
                allShare.splice(0, 1);
                if (allShare.length > 0) {
                    animeShare(toShow, size, allShare);
                }
            });
        }
        else {
            var allShareSize = allShare.length - 1;
            allShare[allShareSize].id.animate({
                top: allShare[allShareSize].topHide,
                left: allShare[allShareSize].leftHide,
                width: size,
                height: size
            }, time, function () {
                allShare[allShareSize].id.hide();
                allShare.pop();
                if (allShare.length > 0) {
                    animeShare(toShow, size, allShare);
                }
            });
        }
    }

}(jQuery));