jQuery(function ($) {
    var $error404 = $('body.error404');
    if ($error404.length > 0) {
        function adapt404() {
            var $inside404 = $error404.find('.error-404');
            $inside404.height($inside404.find('img').height());
        }

        adapt404();
        $(window).on('resize', function () {
            adapt404();
        });
    }
});