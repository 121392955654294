(function ($) {

    // Smooth scroll link
    $(document).on('click', '.js-smooth-scroll', function () {
        var speed = 750,
            link = $(this).attr('href');
        $('html, body').animate({
            scrollTop: $(link).offset().top
        }, speed);
        return false;
    });

    $(document).keydown(function (e) {
        if (e.which === 13) {
            $('.overlay').remove();
        }
    });

    $('.eolia_input--back-button').on('click', function(e) {
        e.preventDefault();

        history.go(-1);
        return false;
    })

}(jQuery));