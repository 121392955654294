jQuery(function ($) {
    var $archiveJob = $('body.post-type-archive-job');
    if ($archiveJob.length > 0) {

        // Handle select fields
        $('.select2-container').each(function () {
            $(this).on('click', function () {
                var $openedContainer = $('.select2-container--open'),
                    $overlay = $('<div/>', {'class': 'overlay'});
                if ($(window).width() < 768) {
                    $('body').append($overlay);
                }
                if ($openedContainer.length === 2) {
                    var $initialSelector = $openedContainer.first(),
                        $dynamicList = $openedContainer.last();

                    $dynamicList.css('top', parseInt($initialSelector.position().top)).show();

                    $(document).mouseup(function (e) {
                        // if the target of the click isn't the container nor a descendant of the container
                        if (!$dynamicList.is(e.target) && $dynamicList.has(e.target).length === 0) {
                            $dynamicList.hide();
                            if ($initialSelector.siblings('select').val()) {
                                var $group = $initialSelector.parent('.eolia_form-group');
                                // $group.find('label.error').remove();
                                $group.find('.error').removeClass('error');
                            }
                        }

                        $overlay.remove();
                    });
                }
            });
        });
    }
});