jQuery(function ($) {
    var $apply = $('body.apply') || $('body.single-apply');
    if ($apply.length > 0) {

        // Handle select fields
        $('.select2-container').on('click', function () {
            var $openedContainer = $('.select2-container--open'),
                $overlay         = $('<div/>', {'class': 'overlay'});
            if ($(window).width() < 768) {
                $('body').append($overlay);
            }

            if ($openedContainer.length === 2) {
                var $initialSelector = $openedContainer.first(),
                    $dynamicList     = $openedContainer.last();

                $dynamicList.css('top', parseInt($initialSelector.position().top)).show();

                $(document).mouseup(function (e) {
                    // if the target of the click isn't the container nor a descendant of the container
                    if (!$dynamicList.is(e.target) && $dynamicList.has(e.target).length === 0) {
                        $dynamicList.hide();
                        if ($initialSelector.siblings('select').val()) {
                            var $group = $initialSelector.parent('.eolia_form-group');
                            // $group.find('label.error').remove();
                            $group.find('.error').removeClass('error');
                        }
                    }

                    $overlay.remove();
                });
            }
        });

        // Handle error on select
        $apply.find('input[type="file"]').on('change', function () {
            $(this).parents('.eolia_form-group').removeClass('error').parent().find('label.error').remove();
        });

        // Handle error on focused field -> write the proper error message
        $(document).on('blur keyup', ':input.error', function () {
            var $formGroup = $(this).parents('.eolia_form-group'),
                $email     = $formGroup.find('#email');

            $formGroup.find('label.error:not("#email-error-invalid")').text('Ce champ est requis');
            if ($email.length > 0) {
                checkEmailValidity($email);
            }
        });

        // Handle form submission
        $apply.find('#eolia_form').on('submit', function (e) {
            var $form  = $(this),
                $email = $form.find('#email');

            $form.find('label.error').text('Ce champ est requis');
            $form.find('#email-error-invalid').text('Adresse email invalide');

            if (!checkEmailValidity($email)) {
                $email.focus();
                return false;
            }
        });

        // Handle email field, it has to be valid
        function checkEmailValidity($emailField) {
            var re           = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                $emailInvald = $emailField.siblings('#email-error-invalid');
            if (!re.test($emailField.val().toLowerCase())) {
                $emailField.addClass('error');
                if ($emailInvald.length <= 0) {
                    $emailField.parent().append('<label id="email-error-invalid" class="error" for="email">Adresse email invalide</label>');
                } else {
                    $emailInvald.text('Adresse email invalide').show();
                }
                return false;
            }

            return true;
        }

        $apply.find('#email').on('blur', function () {
            checkEmailValidity($(this));
        });
    }
});